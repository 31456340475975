import Modal from "@/components/Modal"
import React, { useRef, useState } from "react"
import { usePromptInputStore } from "@/stores"
import { useWorkspacePrompt } from "@/components/Tools/Prompts"
import SearchBar from "@/components/Workspace/FoldersV2/SearchBar"
import LoadingLogo from "@/components/LoadingLogo"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import { motion } from "framer-motion"
import InfiniteScroll from "react-infinite-scroller"
import PromptItem from "@/components/Tools/Prompts/PromptItem"
import { cn } from "@/lib/utils"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import ErrorUI from "@/components/ErrorUI"

const PromptsLibraryModal = ({ className }: { className?: string }) => {
  const { promptInput, setPromptInput } = usePromptInputStore()
  const [search, setSearch] = useState("")
  const scrollRef = useRef<HTMLDivElement>(null)

  const { prompts, isLoading, isFetching, fetchNextPage, hasNextPage, isError } = useWorkspacePrompt(
    search,
    undefined,
    !!promptInput && promptInput?.modalType === "PromptsLibraryModal",
  )

  const handleClose = () => {
    setPromptInput(null)
  }
  const handleConfirmSearch = (search: string) => {
    setSearch(search)
  }

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    if (prompts.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1 list-recipes"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={false}
          threshold={400}
          getScrollParent={() => scrollRef.current}
        >
          <GridBreakpointColsContainer className="gap-2 md:gap-4 grid-cols-2 md:grid-cols-[repeat(auto-fill,minmax(16rem,1fr))]">
            {prompts.map((promptData, idx) => (
              <PromptItem
                hiddenMenu
                key={promptData.id}
                className={`prompt-${idx + 1}`}
                prompt={promptData}
                onClick={() => {
                  promptInput?.onChange(promptData.prompt)
                  handleClose()
                }}
              />
            ))}
          </GridBreakpointColsContainer>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <Modal
      title={"Prompts Library"}
      isOpen={promptInput?.modalType === "PromptsLibraryModal"}
      onClose={handleClose}
      bodyClassName="overflow-hidden p-0"
      className="relative w-full max-w-6xl overflow-hidden rounded-2xl bg-atherGray-900 modal-content-child"
      containerClassName={cn("md:backdrop-blur-xl overflow-hidden", className)}
    >
      <div className="flex flex-col flex-1 w-full overflow-hidden">
        <div className="overflow-hidden flex-1 flex flex-col">
          <div className={"flex justify-end relative mb-2 px-4 py-2"}>
            <SearchBar
              isMobileMode={false}
              disabledSearchQuery
              search={search}
              setSearch={setSearch}
              inputClassName="w-80 bg-atherGray-850"
              onConfirmSearch={handleConfirmSearch}
              onClear={() => handleConfirmSearch("")}
            />
          </div>
          <div ref={scrollRef} className="flex flex-1 w-full overflow-auto min-h-[24rem] p-4 max-h-[calc(100vh-20rem)]">
            {renderBody()}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PromptsLibraryModal
